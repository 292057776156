export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-video-story"]'
  );

  if (elements.length) {
    const { default: VideoStory } = await import(
      /* webpackChunkName: "o-video-story" */ './video-story.jsx'
    );

    elements.forEach((element) => new VideoStory(element));
  }
}
