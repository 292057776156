export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="o-line-up"]');

  if (elements.length) {
    const { default: LineUp } = await import(
      /* webpackChunkName: "o-line-up" */ './LineUp'
    );

    elements.forEach((element) => new LineUp(element));
  }
}
