export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-ticket-countdown-teaser"]'
  );

  if (elements.length) {
    const { default: TicketCountdownTeaser } = await import(
      /* webpackChunkName: "o-ticket-countdown-teaser" */ './ticket-countdown-teaser'
    );

    elements.forEach((element) => new TicketCountdownTeaser(element));
  }
}
