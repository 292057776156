import { isFooterVisible } from '../../../../javascripts/stores/footer';
import BaseModule from '../../../../javascripts/helpers/baseModule';

export default class Footer extends BaseModule {
  constructor(element) {
    super(element, element.getAttribute('data-js-module'));

    this.getElements([]);

    this.setupIntersectionObserver();
    this.setupResizeObserver();
  }

  setupIntersectionObserver() {
    const cb = ([entry]) => {
      isFooterVisible.set(entry.isIntersecting);
    };

    const obs = new IntersectionObserver(cb);
    obs.observe(this.elements.self);
  }

  setupResizeObserver() {
    const cb = ([entry]) => {
      document.documentElement.style.setProperty(
        '--mm-footer-height',
        `${entry.contentRect.height}px`
      );
    };
    const obs = new ResizeObserver(cb);
    obs.observe(this.elements.self);
  }
}
