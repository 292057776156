const instances = new WeakMap();

export default function init() {
  const sliderStages = document.querySelectorAll(
    '[data-js-element="o-teaser-slider__slide-wrapper"]'
  );

  const init = async () => {
    // get tiny slider
    const { tns } = await import(
      /* webpackChunkName: "tiny-slider" */ 'tiny-slider'
    );

    // initialize slider
    sliderStages.forEach((element) => {
      if (instances.has(element)) {
        return;
      }

      const instance = tns({
        container: element,
        arrowKeys: true,
        mouseDrag: true,
        loop: true,
        gutter: 18,
        nav: false,
        controls: true,
        controlsContainer: `[data-js-slider-controls="${element.dataset.jsSliderSlug}"]`,
        speed: 800,
        autoplay: element.dataset.jsSliderAutoplay !== 'false',
        autoplayTimeout: element.dataset.jsSliderTimeout
          ? Number(element.dataset.jsSliderTimeout)
          : 8000
      });

      instances.set(element, instance);
    });
  };

  if (sliderStages.length) init();
}
