export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="o-highlight-video-teaser"]'
  );

  if (elements.length) {
    const { default: HighlightVideoTeaser } = await import(
      /* webpackChunkName: "o-highlight-video-teaser" */ './highlight-video-teaser.jsx'
    );

    elements.forEach((element) => new HighlightVideoTeaser(element));
  }
}
