export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="o-search-bar"]');
  if (elements.length) {
    const { default: SearchBar } = await import(
      /* webpackChunkName: "o-search-bar" */ './SearchBar.tsx'
    );

    elements.forEach((element) => new SearchBar(element));
  }
}
