export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="m-callout"]');
  if (elements.length) {
    const { default: Callout } = await import(
      /* webpackChunkName: "m-callout" */ './callout'
    );

    elements.forEach((element) => new Callout(element));
  }
}
