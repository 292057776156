const countDownEvents = {
  startCountDown: 'startCountDown',
  availableAtRunning: 'availableAtRunning',
  availableAtExpired: 'availableAtExpired',
  expiresAtRunning: 'expiresAtRunning',
  expiresAtExpired: 'expiresAtExpired',
  availableAtLessThanHour: 'availableAtLessThanHour',
  expiresLessThanDay: 'expiresLessThanDay',
  expiresLessThanTwoWeeks: 'expiresLessThanTwoWeeks',
  countDownLessThanDay: 'countDownLessThanDay'
};

export default countDownEvents;
